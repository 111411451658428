import React, { useState, useEffect, useRef } from 'react';

const InstagramReels = ({ instagramSectionTitle, instagramLink }) => {
  const [instagramPosts, setInstagramPosts] = useState([]);
  const [activeVideoIndex, setActiveVideoIndex] = useState(null); // Track active video index
  const videoRefs = useRef([]); // Store video element refs

  useEffect(() => {
    // Fetch data from the API
    fetch('https://theworldgrad.wpengine.com/wp-json/custom/v1/getreels')
      .then((response) => response.json())
      .then((data) => {
        const videoPosts = data.map((post) => ({
          id: post.id,
          caption: post.caption,
          videoUrl: post.media_url, // Video URL for the video
          link: post.permalink,
        }));
        setInstagramPosts(videoPosts);
      })
      .catch((error) => console.error('Error fetching Instagram posts:', error));
  }, []);

  const handlePlay = (index) => {
    // Set the active video index when a video starts playing
    setActiveVideoIndex(index);

    // Pause all other videos
    videoRefs.current.forEach((video, videoIndex) => {
      if (video && videoIndex !== index) {
        video.pause();
      }
    });
  };

  const handlePause = (index) => {
    // If the video is paused, clear the active video index
    if (activeVideoIndex === index) {
      setActiveVideoIndex(null);
    }
  };

  return (
    <section className="half-section">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 p-0 margin-two-bottom text-center wow animate__slideInLeft">
            <h2 className="color-blue">{instagramSectionTitle}</h2>
          </div>
        </div>
        <div className="row">
          {instagramPosts.map((post, index) => (
            <div className="col-md-4 mb-3" key={post.id}>
              <div className="instagram-post">
                <a href={post.link} target="_blank" rel="noopener noreferrer">
                  <video
                    ref={(el) => (videoRefs.current[index] = el)} 
                    className="w-100"
                    src={post.videoUrl}
                    alt={`Instagram Video ${post.id}`}
                    onMouseEnter={(e) => e.target.setAttribute('controls', true)} 
  onMouseLeave={(e) => e.target.removeAttribute('controls')} 
  
                    onClick={() => handlePlay(index)} 
                    onPlay={() => handlePlay(index)} 
                    onPause={() => handlePause(index)} 
                   
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-12 text-center mt-5">
            <a href={instagramLink} className="btn-style-new bg-orng-2">
              Watch More On{' '}
              <img
                src="https://assets.theworldgrad.com/wp-content/uploads/2023/07/instagram.png"
                alt="instagram"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InstagramReels;
